import { Component, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-page-dashboard',
  templateUrl: './page-dashboard.component.html'
})
export class PageDashboardComponent {

  isExpanded = false;

  constructor(private sidebarService: SidebarService) {
    this.sidebarService.collapsedStatusSubject.subscribe(expanded => {
      this.isExpanded = expanded;
    })
  }
}
