import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { AbstractCustomControlComponent } from './components/abstract-custom-control/abstract-custom-control.component';
import { AbstractNestedFormComponent } from './components/abstract-nested-form/abstract-nested-form.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { CollapsibleFieldsetComponent } from './components/collapsible-fieldset/collapsible-fieldset.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { CustomAlertComponent } from './components/custom-alert/custom-alert.component';
import { CustomPagerComponent } from './components/custom-pager/custom-pager.component';
import { HeaderComponent } from './components/header/header.component';
import { InputMonthYear } from './components/input-month-year/input-month-year.component';
import { PageSizeSelectorComponent } from './components/page-size-selector/page-size-selector.component';
import { ReplaceNull } from './components/pipes/replace-null-pipe.component';
import { SortToggleComponent } from './components/sort-toggle/sort-toggle.component';
import { FieldErrorDirective } from './directives/field-error.directive';
import { PlaceholderDirective } from './directives/placeholder.directive';
import { SelectOnClickDirective } from './directives/select-on-click.directive';

const DIRECTIVES = [
    PlaceholderDirective,
    FieldErrorDirective,
    SelectOnClickDirective
];

const COMPONENTS = [
    ConfirmModalComponent,
    HeaderComponent,
    AbstractCustomControlComponent,
    AbstractNestedFormComponent,
    CollapsibleFieldsetComponent,
    SortToggleComponent,
    PageSizeSelectorComponent,
    CustomAlertComponent,
    CustomPagerComponent,
    BackButtonComponent
];

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        NgxScrollTopModule
    ],
    declarations: [
        ...DIRECTIVES,
        ...COMPONENTS,
        InputMonthYear,
        ReplaceNull
    ],
    exports: [
        ...DIRECTIVES,
        ...COMPONENTS,
        CommonModule,
        MatIconModule,
        TranslateModule,
        MatDatepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        TooltipModule,
        PopoverModule,
        ToastrModule,
        ToastContainerModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        InputMonthYear,
        ReplaceNull,
        NgxScrollTopModule
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['l', 'LL',],
                },
                display: {
                    dateInput: 'L',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {
                useUtc: true,
                strict: true,
            }
        }
    ]
})
export class SharedModule { }
