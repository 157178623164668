<div class="flex justify-between p-4 border-b-2">
    <h3 class="text-xl">{{modalConfig.title | translate}}</h3>
    <button type="button" class="border-0" aria-label="Close" (click)="cancel()">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="px-4 py-6">
    {{modalConfig.message | translate}}
</div>
<div class="flex justify-end p-4 border-t-2">
    <button (click)="cancel()" type="button" class="button--secondary mr-3" data-dismiss="modal">{{'GENERAL.RESET' | translate}}</button>
    <button (click)="confirm()" type="button" class="button--primary" data-dismiss="modal">{{'GENERAL.CONFIRM' | translate}}</button>
</div>