import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToastContainerModule, ToastrModule} from 'ngx-toastr';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PageDashboardComponent} from './components/page-dashboard/page-dashboard.component';
import {PageHomeComponent} from './components/page-home/page-home.component';
import {SidebarActionComponent} from './components/sidebar-action/sidebar-action.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {CoreModule} from './core.module';
import {SharedModule} from './shared/shared.module';
import {KeycloakSecurityService} from "./services/keycloak-security.service";
import {RequestInterceptorService} from "./services/request-interceptor.service";


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeIt);

export function kcFactory(kcSecurity: KeycloakSecurityService) {
  return () => kcSecurity.init()
}

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    PageHomeComponent,
    PageDashboardComponent,
    SidebarActionComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    SharedModule,
    CoreModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: APP_INITIALIZER, deps: [KeycloakSecurityService], useFactory: kcFactory, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true}


  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
