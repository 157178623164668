import {Component, Input} from '@angular/core';
import {Action} from '../sidebar/sidebar.component';
import {KeycloakSecurityService} from "../../services/keycloak-security.service";


@Component({
  selector: 'app-sidebar-action',
  templateUrl: './sidebar-action.component.html'
})
export class SidebarActionComponent {
  @Input() action: Action;

  constructor(private keycloakService: KeycloakSecurityService,
  ) {
  }


  isAdmin(){
    return this.keycloakService.keycloak.hasRealmRole("admin"); }
}
