import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";

declare var Keycloak: any;

@Injectable({
  providedIn: 'root'
})
export class KeycloakSecurityService {

  public keycloak;
  kc_url= environment.keycloak.kc_auth_url;
  kc_realm= environment.keycloak.realm;
  kc_clientId= environment.keycloak.clientId;

  constructor(private http: HttpClient) {
  }

  init() {
    return new Promise((resolve, reject) => {
      console.log('INIT : Service keycloak security ');
      this.keycloak = new Keycloak({
        url: this.kc_url,
        realm: this.kc_realm,
        clientId: this.kc_clientId,
      });
      this.keycloak.init({
        onLoad: 'login-required'
        //onLoad: 'check-sso',
        //promiseType: 'native'
      }).then((authenticated) => {
        console.log('authenticated', authenticated);
        console.log('token: ', this.keycloak.token);
        resolve({authenticated, token: this.keycloak.token})
      }).catch(err => {
        reject(err);
      });
    });


  }

  public isAdmin(): boolean {
    return this.keycloak.hasResourceRole("admin");
  }
}