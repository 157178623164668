export const environment = {
  production: window["env"]["CNOSSO_FRONTEND_PRODUCTION"],
  mock: false,
  collaudo: false,
  http: {
    baseurl: window["env"]["CNOSSO_FRONTEND_HTTP_API_ROOT"],
  },
    keycloak :{
    kc_auth_url:  window["env"]["CNOSSO_FRONTEND_KEYCLOAK_URI"], //'http://localhost:58080/auth',
    realm: 'magic-folders-admin',
    clientId: 'angular-app',
  }
};