import { Location, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RouteConfig } from "src/app/models/route-config.interface";


@Component({
    selector: 'app-back-button',
    templateUrl: './back-button.component.html',
    providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
})
export class BackButtonComponent {

    location: Location;
    _routeConfig: RouteConfig;
    loading = false;

    @Input() set routeConfig(config: RouteConfig) {
        this._routeConfig = config;
    }

    constructor(private router: Router, private route: ActivatedRoute, location: Location) {

    }

    goToRoute() {

        this.loading = true;

        const callPage = this.route.snapshot.queryParams?.callPage;

        if(!!callPage) {
          this.router.navigate([callPage], {queryParams: {...this.route.snapshot.queryParams, callPage: null}});
          return;
        }

        if(!!this._routeConfig) {
            this.router.navigate(this._routeConfig.route, this._routeConfig.navigationExtras);
            return;
        }

        this.location.back();
    }
}
