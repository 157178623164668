import { Component, OnInit } from '@angular/core';
import { FormGroup, ValidationErrors, AbstractControl, Validator, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-abstract-nested-form',
  templateUrl: './abstract-nested-form.component.html'
})
export class AbstractNestedFormComponent implements ControlValueAccessor, Validator {

  nestedForm: FormGroup;
  submitted = false;

  constructor() { }

  get f() {
    return this.nestedForm.controls;
  }

  onTouched = () => { };

  writeValue(val: any): void {
    if (val) {
      this.nestedForm.patchValue(val);
    }
  }
  registerOnChange(fn: any): void {
    this.nestedForm.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.nestedForm.disable() : this.nestedForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.nestedForm.valid ? null : { invalidForm: {valid: false, message: 'nestedForm fields are invalid'}};
  }

  protected updateFormState() {
    if(this.nestedForm) {
        this.nestedForm.markAsPristine();
    }
  }

  onNumericInput(controlName) {
    this.nestedForm.patchValue({
        [controlName]: (+this.nestedForm.controls[controlName].value).toFixed(2)
    });
  }
}
