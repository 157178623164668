<ng-container>
  <div *ngIf="!action.callback"
       [routerLinkActiveOptions]="{exact:action.exact}"
       [routerLink]="action.destination"
       class="sidebar-action"
       routerLinkActive="active-link">
    <div class="tooltip">
      {{action.label | translate}}
    </div>
    <mat-icon [fontIcon]="action.icon" [fontSet]="action.iconset ?? 'fa'" class="material-icons"></mat-icon>
  </div>

  <div (click)="action.callback()" *ngIf="action.callback"
       class="sidebar-action">
    <div class="tooltip">
      {{action.label | translate}}
    </div>
    <mat-icon [fontIcon]="action.icon" [fontSet]="action.iconset ?? 'fa'" class="material-icons"></mat-icon>
  </div>
</ng-container>
