<div class="my-5">
  <div class="card-block shadow max-w-max text-white bg-th-primary rounded-md">
    {{'DASHBOARD.WELCOME' | translate}} <strong class="ml-1">{{name}}</strong>!
  </div>
</div>


<div class="grid md:grid-cols-1 lg:grid-cols-2 gap-4">
  <div class="card-block rounded-xl detail-info shadow">
    <div class="my-1">
      <h2 class="font-bold text-2xl">
        {{name}}
      </h2>
    </div>
    <div class="my-1">
      <span class="font-bold mr-1">{{ 'USERS.USER_FORM.FIELDS.EMAIL' | translate }}:</span>
      {{email}}
    </div>
  </div>
</div>

