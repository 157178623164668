<div class="group flex-full-width" 
    [ngClass]="{'panel-open': !!isBoxOpen, 'panel-closed': !isBoxOpen, 'success': isValid, 'danger': !isValid}">
    <div class="group-name d-flex align-items-center" (click)="toggle()">
        {{label | translate}} - 
        <span class="ml-1 show-button">{{(isBoxOpen ?'GENERAL.HIDE':'GENERAL.SHOW') | translate}}</span>
        <mat-icon fontSet="fa"
            tooltip="{{(isValid ? 'FORM.MESSAGES.FIELDSET_SUCCESS' : 'FORM.MESSAGES.FIELDSET_ERROR') | translate}}"
            [fontIcon]="isValid ? 'fa-check-circle':'fa-exclamation-circle'" 
            [ngClass]="{'success': isValid, 'danger': !isValid}"
            class="ml-2 material-icons">
        </mat-icon>
    </div>
    <ng-container *ngIf="isBoxOpen">
        <ng-content></ng-content>
    </ng-container>
</div>
