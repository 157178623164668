import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, tap } from 'rxjs/operators';
import { ErrorPayload } from '../models/error-payload.model';
import { of } from 'rxjs';
import { RequestContext } from '../models/request-context';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  showSuccess(message, title) {
      this.toastr.success(message, title);
  }

  showError(message, title) {
      this.toastr.error(message, title);
  }

  showInfo(message, title) {
      this.toastr.info(message, title);
  }

  showWarning(message, title) {
      this.toastr.warning(message, title);
  }

  handleError(error: any, message?: string) {
    const errors = this.getErrorMessages(error);
    this.translateService.get(['GENERAL.ERROR', message, ...errors]).subscribe(res => {
      errors.forEach(element => {
        this.showError(res[element], res['GENERAL.ERROR']);
      });
    });
  }

  handleApiError(error: ErrorPayload) {

    let showAlert = this.showError.bind(this);
    let alertTitle = 'GENERAL.ERROR';

    error.forEach(err => {
      console.error('ERROR', err.defaultMessage);
      let errorArgs;
      let obs = of(null);
      const errorMessage = ['ERRORS', err.namespace, err.key].join('.');

      let args = err.args?.reduce((acc,item, index) => {
        acc['arg'+index] = item;
        return acc;
      }, {})
      obs.pipe(
        switchMap(_ =>
          this.translateService.get(
            [alertTitle, errorMessage],
            args
          )
        )
      ).subscribe(res => {
        showAlert(res[errorMessage], res[alertTitle]);
      });
    });
  }

  handleSuccess(message: string) {
    this.translateService.get(['GENERAL.SUCCESS', message]).subscribe(res => {
      this.showSuccess(res[message], res['GENERAL.SUCCESS']);
    });
  }

  private getErrorMessages(error: any) {
    if (!!error?.error?.global) {
      return error.error.global.map(item => `ERRORS.${item}`);
    }
    return ['ERRORS.UNKNOWNN'];
  }
}
