<div class="date-container">
    <input placeholder="MM/YYYY" 
        class="form-input w-full" 
        matInput 
        [matDatepickerFilter]="datePickerSelectionFilter"
        [matDatepicker]="picker" 
        [formControl]="period" 
        [appFieldError]="errors"/>
    <mat-datepicker-toggle matSuffix [for]="picker" class="date-picker flex items-center"></mat-datepicker-toggle>
    <mat-datepicker #picker
        startView="multi-year"
        (monthSelected)="chosenMonthHandler($event, picker)"
        >
    </mat-datepicker>
</div>
