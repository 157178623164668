import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'app-alert',
    templateUrl: './custom-alert.component.html'
  })
export class CustomAlertComponent {

    @Input() message: string;
    @Output() dismissAlert = new EventEmitter<void>();

    onClose() {
      this.dismissAlert.emit();
    }
}
