import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({providedIn: 'root'})
export class SidebarService {
    private _collapsedStatus = false;
    collapsedStatusSubject = new BehaviorSubject<boolean>(false);

    setCollapsedStatus(status: boolean) {  
        this._collapsedStatus = status;
        this.collapsedStatusSubject.next(status);
    }

    getCollapsedStatus(): boolean {
        return this._collapsedStatus;
    }
}