import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {KeycloakSecurityService} from "./services/keycloak-security.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  isAuth = false;
  keycloak: any;
  userInformations: any;

  constructor(
      private keycloakSecurityService: KeycloakSecurityService,
      translate: TranslateService,
  ) {
    translate.addLangs(['it']);
    translate.setDefaultLang('it');
    translate.use('it');
  }

  ngOnInit() {
    this.keycloak = this.keycloakSecurityService.keycloak;
    console.log(this.keycloak);
    // console.log('hasRealmRole', this.keycloak.hasRealmRole('app-manager'));
    // console.log('hasResourceRole', this.keycloak.hasResourceRole('app-manager'));
    this.isAuth = this.keycloak.authenticated;
    this.userInformations = this.isAuth ? this.keycloak.idTokenParsed : {};
  }

  onLogin() {
    this.keycloak.login();
  }

  onLogout() {
    this.keycloak.logout();
  }


}
