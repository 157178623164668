<div class="CustomPager">

    <div class="justify-self-start">
        <pagination  
            *ngIf="totalElements > itemsPerPage"
            [(ngModel)]="innerPage"
            [itemsPerPage]="itemsPerPage" 
            [customPreviousTemplate]="prevTemplate"
            [customNextTemplate]="nextTemplate"
            [customFirstTemplate]="firstTemplate"
            [customLastTemplate]="lastTemplate"
            [maxSize]="maxSize" 
            [totalItems]="totalElements" 
            [rotate]="false"
            [boundaryLinks]="true" 
            (ngModelChange)="onModelChange($event)">
        </pagination>
    </div>
    <div class="justify-self-end" *ngIf="showPageSizeSelector">
        <app-page-size-selector [size]="itemsPerPage" (sizeSelected)="onSizeSelected($event)"></app-page-size-selector>
    </div>
</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <mat-icon fontSet="fa" fontIcon="fa-chevron-right" class="material-icons"></mat-icon>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <mat-icon fontSet="fa" fontIcon="fa-chevron-left" class="material-icons"></mat-icon>
</ng-template>

<ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
    <mat-icon fontSet="fa" fontIcon="fa-angle-double-right" class="material-icons"></mat-icon>
</ng-template>

<ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
    <mat-icon fontSet="fa" fontIcon="fa-angle-double-left" class="material-icons"></mat-icon>
</ng-template>