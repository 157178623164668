import { Component, OnInit, forwardRef, Output, Input, Optional, Self, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor,
  Validator, AbstractControl, ValidationErrors, FormControl, NgControl
} from '@angular/forms';

@Component({
  selector: 'app-abstract-custom-control',
  templateUrl: './abstract-custom-control.component.html'
})
export class AbstractCustomControlComponent implements ControlValueAccessor, Validator {

  @Input() label: string;
  @Input() ariaLabel: string;
  @Output() changed = new EventEmitter<any>();

  value: any;
  disabled = false;
  required = false;

  constructor() {}

  onChange = (_value: any) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.value = value;

    this.onChange(this.value);
    this.changed.emit(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return c.valid ? null : { invalidForm: {valid: false, message: "fields are invalid"}};
  }
}
