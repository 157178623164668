<div class="btn-group" dropdown [dropup]="true">
    <button id="button-basic" dropdownToggle type="button" class="button--secondary shadow dropdown-toggle flex items-center"
        aria-controls="dropdown-basic">
        {{selectedSize}} {{'FORM.FIELDS.ITEMS_PER_PAGE' | translate}}
        <mat-icon fontSet="fa" fontIcon="fa-angle-up" class="material-icons fa-1x ml-3"></mat-icon>
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" aria-labelledby="button-basic"
        role="menu" aria-labelledby="button-basic">
      <li class="dropdown-item cursor-pointer" *ngFor="let item of sizes" (click)="onSelectSize(item)">{{item}}</li>
    </ul>
</div>
