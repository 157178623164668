import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  @Input() pageName: string;
  @Input() icon: string;
  @Input() hideTitle: boolean;
  @Input() removeTitle: boolean;

  constructor() { }

  ngOnInit() {
  }

}
