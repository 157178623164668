import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from 'moment';
import { Utils } from 'src/app/utils';


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-input-month-year',
  templateUrl: './input-month-year.component.html',
  providers:[
    {provide: MAT_DATE_FORMATS, useValue:MY_FORMATS}
  ]
})

export class InputMonthYear implements OnInit {

  period = new FormControl(null, this.periodValidator);

  @Output() periodUpdated = new EventEmitter();

  get errors() {
    let errors = {...this.period.errors};
    if(errors.matDatepickerParse) {
      delete errors.matDatepickerParse;
      errors = {
        ...errors,
        periodPatternInvalid: true
      }
    }
    if(errors.matDatepickerFilter) {
      delete errors.matDatepickerFilter;
    }

    if(!Object.keys(errors).length) {
      return null;
    }

    return errors;
  }

  ngOnInit() {
    this.period.valueChanges.subscribe(val => {
      if(val) {
        let split = moment(val).format('MM/YYYY').split("/");
        this.periodUpdated.emit(
          {
            mese:parseInt(split[0]),
            anno:parseInt(split[1])
          }
        )
      } 
      if(this.period.invalid) {
        this.periodUpdated.emit({mese: -1, anno: -1});
      }
      if(!val && this.period.valid) {
        this.periodUpdated.emit({mese: null, anno: null});
      }
    })
  }
  
  chosenMonthHandler(period, datepicker) {
    this.period.setValue(period);
    this.periodUpdated.emit({
      mese:period.month()+1,
      anno:period.year()
    });
    datepicker.close();
  }

  reset(){
    this.period.reset();
  }

datePickerSelectionFilter(d: Date | null) {
    return moment(d || new Date()).isBetween(moment(`${Utils.minYearSearch}-01-01`), moment());
}

  periodValidator(formControl: FormControl): {[s: string]: {}} {

    if(!formControl.value) {
      return null;
    }

    const [month,year] = formControl.value.format('MM/YYYY').split('/');

    if(month < 1 || month > 12) {
      return {between: {
        min: 1,
        max: 12
      }};
    }

    if(year < Utils.minYearSearch || year > Utils.maxYearSearch) {
      return {between: {
        min: Utils.minYearSearch,
        max: Utils.maxYearSearch
      }};
    }

    return null;
  }

}
