import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {of} from "rxjs";
import {KeycloakSecurityService} from "./keycloak-security.service";
import {exhaustMap, take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {

  constructor(private keycloakSecurityService: KeycloakSecurityService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    console.log('request interceptor ...')
    return of(this.keycloakSecurityService.keycloak)
    .pipe(
        take(1),
        exhaustMap(keycloak => {
          if (!keycloak.authenticated) {
            return next.handle(req);
          }
          const modifiedReq = req.clone(
              {
                setHeaders: {
                  Authorization: 'Bearer ' + keycloak.token
                }
              }
          );
          return next.handle(modifiedReq);
        })
    );
  }
}