<div class="dashboard-container">
  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="dashboard" [ngClass]="{'expanded': isExpanded}">
    <router-outlet></router-outlet>
  </div>
</div>
<ngx-scrolltop
    theme="custom"
    position="right">
    <mat-icon fontSet="fa" fontIcon="fa-angle-up" class="material-icons"></mat-icon>
</ngx-scrolltop>
