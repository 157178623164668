import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Directive({
    selector: '[appFieldError]'
})
export class FieldErrorDirective {

    errors: {type: string, params: any}[];
    ulElement: HTMLUListElement;

    constructor(private el: ElementRef, private translateService: TranslateService) { 
        this.ulElement = document.createElement('ul') as HTMLUListElement;
        this.ulElement.classList.add('fieldErrors');
        (this.el.nativeElement as HTMLElement).insertAdjacentElement('afterend',this.ulElement);
    }

    @Input() fieldName = '';

    @Input('appFieldError') set fieldErrors(errors: any) {
        if(!errors) {
            return;
        }
        this.errors = Object.keys(errors).map(it => Object.assign({}, {
            type: it, params: errors[it]
        }));
        this.createErrorList();
    }

    private createErrorList() {
        if(!this.errors && this.el.nativeElement.classList.contains('ng-valid')) {
            return;
        }

        this.translateService.get(
            this.errors.map(it => `FORM.ERRORS.${it.type}`),
            {
                fieldName: this.fieldName,
                ...this.errors.reduce((acc,item) => {
                    acc = {...acc, ...item.params};
                    return acc;
                }, {})
            }
        ).subscribe(errorMessages => {
            this.ulElement.innerHTML = null;
            this.errors.forEach(it => {
                const liElement = document.createElement('li') as HTMLLIElement;
                liElement.innerText = errorMessages[`FORM.ERRORS.${it.type}`];
                this.ulElement.appendChild(liElement);
            });
        });         
    }
}