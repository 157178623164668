import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Utils } from '../../../utils';

@Component({
  selector: 'app-page-size-selector',
  templateUrl: './page-size-selector.component.html'
})
export class PageSizeSelectorComponent {

  selectedSize: number;
  sizes = [5, 10, 25, 50];

  @Input() set size(size: number) {
    if(size !== this.selectedSize) {
      this.selectedSize = size
    }
  }

  @Output() sizeSelected = new EventEmitter<number>();

  constructor() { }

  onSelectSize(size) {
    this.selectedSize = size;
    this.sizeSelected.emit(size);
  }
}
