import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {SidebarService} from 'src/app/services/sidebar.service';
import {KeycloakSecurityService} from "../../services/keycloak-security.service";

type IconSet = 'fa' | 'fas' | 'far';

export interface Action {
  icon: string;
  label: string;
  exact: boolean;
  destination: string;
  //enabled?: boolean;
  //permissions?: string[];
  customCondition?: boolean;
  iconset?: IconSet;
  callback?: () => void;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})


export class SidebarComponent {
  isCollapsed = false;
  topSidebarActions: Action[];
  bottomSidebarActions: Action[];
  displayMobile = false;

  constructor(
      private router: Router,
      private keycloakService: KeycloakSecurityService,
      private sidebarService: SidebarService
  ) {
    this.topSidebarActions = [
      {
        icon: 'fa-home',
        label: 'ROUTES.HOME',
        exact: true,
        destination: '',
      },
      {
        icon: 'fa-users',
        label: 'customer',
        exact: true,
        destination: '/customers',
      }
      // {
      //   icon: 'fa-user-cog',
      //   label: 'ROUTES.USER',
      //   exact: false,
      //   destination: '/users',
      //   permissions: ['users_management']
      // },
      // {
      //   icon: 'fa-user-tag',
      //   label: 'ROUTES.ROLES',
      //   exact: false,
      //   destination: '/roles',
      //   permissions: ['roles_management']
      // }
    ];

    this.bottomSidebarActions = [
      // {
      //   icon: 'fa-user-circle',
      //   label: 'ROUTES.PROFILE',
      //   exact: false,
      //   destination: '/profile'
      // },
      {
        icon: 'fa-sign-out-alt',
        label: 'Logout',
        exact: true,
        destination: 'logout',
        callback: () => {
          this.keycloakService.keycloak.logout();
          //  this.userService.unsetUserInfo();
          // this.router.navigate(['/login']);
        }
      }
    ];
  }

  toggleMobile() {
    this.displayMobile = !this.displayMobile;
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.sidebarService.setCollapsedStatus(this.isCollapsed);
  }
}
