import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'replaceNull'})
export class ReplaceNull implements PipeTransform {
    transform(value: string, pipeValue?: any, symbol?: string): string {
        if(pipeValue === null || pipeValue === ' ') {
            return '-';
        }
        if(value === null || value === undefined || value === ' ') {
            return '-';
        }
        if(!!symbol && symbol.trim() !== ' ') {
            return `${value} ${symbol}`;
        }

        return value;
    }
}