import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-collapsible-fieldset',
  templateUrl: './collapsible-fieldset.component.html'
})
export class CollapsibleFieldsetComponent implements OnInit {

  @Input() label: string;
  @Input() subform: FormControl;
  @Input() isOpen: boolean;

  isValid: boolean;
  isBoxOpen: boolean;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.isBoxOpen = this.isOpen;
    this.isValid = this.subform.status === 'VALID';
    this.subform.statusChanges.subscribe(status => {
      this.isValid = status === 'VALID';
    });
  }

  toggle() {
    this.isBoxOpen = !this.isBoxOpen;
  }

}
