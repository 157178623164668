import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from './message.service';
import {RequestContext} from "../models/request-context";
import {appConfig} from "../config/app.config";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private messageService: MessageService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                location.reload();
            }
            console.log(err);
            this.showAlert(request, err);

            const error = err.error.message || err.statusText;
            console.log(error);
            return throwError(error);
        }));
    }

    private showAlert(request: HttpRequest<any>, err: any) {

        if (!Array.isArray(err.error)) {
            return this.messageService.handleError(err);
        }
        this.messageService.handleApiError(err.error);
    }

}
