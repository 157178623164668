import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PageChangedEvent } from "ngx-bootstrap/pagination";

@Component({
    selector: 'app-custom-pager',
    templateUrl: './custom-pager.component.html'
})
export class CustomPagerComponent {
    innerPage: number;

    @Input() set currentPage(page: number) {
        if(!this.innerPage) {
            this.innerPage = page;
        }
        if(page !== this.innerPage) {
            this.innerPage = 1;
        }
        return;
    }
    @Input() totalElements: number;
    @Input() itemsPerPage: number;
    @Input() totalPages: number;
    @Input() maxSize: number;
    @Input() showPageSizeSelector = true;

    @Output() pageChanged = new EventEmitter<number>();
    @Output() sizeSelected = new EventEmitter<number>();

    get lastPage() {
        return `${Math.ceil(this.totalElements / this.itemsPerPage)}`;
    }

    onSizeSelected(size: number) {
        this.sizeSelected.emit(size);
    }

    onModelChange(page: number) {
        this.pageChanged.emit(page);
    }
}