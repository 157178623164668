import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


export interface SortParam {
  paramName: string;
  sortDirection: 'ASC' | 'DESC';
}

@Component({
  selector: 'app-sort-toggle',
  templateUrl: './sort-toggle.component.html'
})
export class SortToggleComponent implements OnInit {

  sortParam: SortParam;
  @Input() paramName: string;
  @Input() sortInitialDirection: 'ASC' | 'DESC';
  @Input() label: string;
  @Output() toggleSort = new EventEmitter<SortParam>();

  constructor() { }

  ngOnInit() {
    this.sortParam = { paramName: this.paramName, sortDirection: this.sortInitialDirection ?? 'ASC' };
  }

  onToggleClick() {
    this.sortParam.sortDirection = this.sortParam.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    this.toggleSort.emit(this.sortParam);
  }

}
