import {Component} from '@angular/core';
import {KeycloakSecurityService} from "../../services/keycloak-security.service";


@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html'
})
export class PageHomeComponent {
  name: string;
  email: string;


  constructor( private keycloakService: KeycloakSecurityService) {
    this.name = this.keycloakService.keycloak.tokenParsed.name;
    this.email = this.keycloakService.keycloak.tokenParsed.email;
  }
}
