import { HttpParams } from "@angular/common/http";

export class Utils {

  public static defaultFirstPage = 0;
  public static defaultPageSize = 15;

  public static minYearSearch = 1980;
  public static maxYearSearch = new Date().getFullYear();
  public static currentMonth = new Date().getMonth() +1;


  public static prepareParams(defaultSort) {
    let params = new HttpParams();

    defaultSort.forEach( param => {
        params = params.append('sort', [param.paramName, param.sortDirection].join(','));
    });

    params = params.append('page', Utils.defaultFirstPage.toString());
    params = params.append('size', Utils.defaultPageSize.toString());

    return params;
}

  public static RandomRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public static Log(debugmessage) {
    console.log(
      "%c Log Message: %c " + debugmessage,
      "margin: 3px; padding: 3px; border-radius: 50px; background: #5EB1BF; color: #191919",
      "background: #222; color: #bada55"
    );
  }

  public static Error(debugmessage) {
    console.log(
      "%c Error Message: %c " + debugmessage,
      "margin: 3px; padding: 3px; border-radius: 50px; background: #D33E43; color: white",
      "background: #222; color: #bada55"
    );
  }
}
