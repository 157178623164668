<div class="header-container flex items-start">
  <div class="header-logo">
    <img class="w-20 h-auto" src="assets/img/realt-med-logo.svg" alt="{{'GENERAL.APP_LOGO' | translate}}" />
  </div>
  <div class="header-title">
    <h1 class="m-0" *ngIf="!removeTitle" [ngClass]="{'invisible':hideTitle}">{{hideTitle?"&nbsp;":"GENERAL.APP_TITLE" | translate}}</h1>
    <h2 class="m-0">
      <mat-icon *ngIf="icon" fontSet="fa" [fontIcon]="icon" class="material-icons"></mat-icon> {{pageName | translate}}
    </h2>
  </div>
</div>
