<div class="sidebar-overlay" *ngIf="displayMobile"></div>
<button class="sidebar-toggle" (click)="toggleMobile()">
  <mat-icon fontSet="fa" [fontIcon]="displayMobile ? 'fa-times' : 'fa-bars'" class="material-icons" [ngClass]="displayMobile ? 'text-2xl' : 'text-xl'"></mat-icon>
</button>
<div class="sidebar-container" [ngClass]="{'d-mobile' : displayMobile, 'collapsed': isCollapsed}">
  <div class="sidebar-section">
    <div class="hidden lg:flex items-center">
      <div class="sidebar-action w-min rounded-full" (click)="toggleCollapse()">
        <mat-icon fontSet="fas" [fontIcon]="isCollapsed ? 'fa-angle-right' : 'fa-angle-left'" class="material-icons"></mat-icon>
      </div>
      <div class="text-center	text-th-primary text-primary text-2xl font-bold leading-none my-5" *ngIf="!isCollapsed">
        {{'GENERAL.APP_TITLE' | translate}}
      </div>
    </div>
    <app-sidebar-action *ngFor="let action of topSidebarActions"
      [action]="action">
    </app-sidebar-action>
  </div>
  <div class="sidebar-section">
    <app-sidebar-action *ngFor="let action of bottomSidebarActions" 
      [action]="action">
    </app-sidebar-action>
  </div>
</div>