import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PageDashboardComponent} from './components/page-dashboard/page-dashboard.component';
import {PageHomeComponent} from './components/page-home/page-home.component';

const routes: Routes = [
  {
    path: '',
    component: PageDashboardComponent,
    children: [
      {path: '', component: PageHomeComponent},
      {
        path: 'customers',
        loadChildren: () => import('./customers/customer.module').then(m => m.CustomerModule)
      }
    ]
  },
 // {path: 'login', redirectTo: 'auth'},
 // {path: 'signup', redirectTo: 'auth/signup'},
 /* {
    path: 'resetPassword',
    redirectTo: 'auth/resetPassword'
  },*/
 /* {
    path: 'complete-registration',
    redirectTo: 'auth/activation'
  },*/
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top'
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
